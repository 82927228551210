@import url('https://fonts.google.com/specimen/Montserrat');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: rgb(230, 226, 208);
  /* margin: 0; */
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body, html {
  min-height: 100%;
  height: 100%;
}

#browser-div {
  background-color: purple;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vh;
} */