#weather-tile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  /* padding: 1rem 1.5rem; */
}

#weather-top-div {
  display: flex;
  height: 70%;
  width: 100%;
  flex-flow: row wrap;
  /* padding: 1rem 1rem 0rem; */
}

#weather-icon-div {
  flex: 50%;
  /* text-align: center; */
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

#weather-icon-img {
  height: 70%;
  filter: invert(99%) sepia(98%) saturate(353%) hue-rotate(310deg) brightness(104%) contrast(80%);
}

#weather-desc {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
}

#weather-temps-div {
  flex: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
}

#weather-temp {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#weather-f {
  font-size: 5rem;
  font-weight: 600;
}

#weather-feels {
  font-size: 1.2rem;
}

#weather-location {
  font-size: 2rem;
  font-weight: 600;
  font-style: italic;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

#weather-bottom-div {
  /* font-size: 100%; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  /* padding: 0rem 1rem 1rem; */
}

#weather-bottom-div h4 {
  font-weight: 400;
}

.weather-bottom-inner-div {
  width: 50%;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  row-gap: 10%;
}

.weather-expected-temps-div {
  font-weight: 500;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-around;
  column-gap: 1rem;
}

#weather-arrow-icon {
  height: 1.5rem;
  transform-origin: center;
}

.weather-arrow-up {
  filter: invert(22%) sepia(94%) saturate(6896%) hue-rotate(1deg) brightness(97%) contrast(122%);
}

.weather-arrow-down {
  filter: invert(13%) sepia(100%) saturate(5083%) hue-rotate(239deg) brightness(84%) contrast(138%);
  transform: scale(1, -1);
}
