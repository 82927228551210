@import url(https://fonts.google.com/specimen/Montserrat);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: rgb(230, 226, 208);
  /* margin: 0; */
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body, html {
  min-height: 100%;
  height: 100%;
}

#browser-div {
  background-color: purple;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vh;
} */
#nav-container {
  height: 20vh;
  min-height: 120px;
  background-color: rgb(12, 36, 63);
}

#nav-profile-div {
  height: 100px;
  width: 100px;
  background-color: blue;
  border: white .25rem solid;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-profile-div p {
  font-size: 3rem;
  font-weight: 600;
}

#nav {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

#nav p {
  font-size: 2rem;
}

#nav li {
  list-style: none;
}

.nav-link {
  text-decoration: none;
  padding: 1rem 3rem;
}

a:visited.nav-link, a:visited.current {
  color: white;
}

.current {
  text-decoration: none;
  padding: 1rem 3rem .5rem;
  border-bottom: .5rem solid black;
}
.App {
  height: 80vh;
  width: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3f4c6b+0,3f4c6b+100;Blue+Grey+Flat */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#193759+0,1c3d66+100 */
  background: #193759; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #193759 0%,#1c3d66 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#193759', endColorstr='#1c3d66',GradientType=0 ); /* IE6-9 */
  /* padding: 1rem; */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

/* .App-header {
  margin: 0 auto;
  font-size: calc(10px + 2vmin);
} */
.tile-container {
    height: 48%;
    width: 48%;
    padding: 1rem;
    border-radius: 25px;
    box-shadow: rgb(2, 2, 2, .8) 0px .3rem .8rem 0px;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,2989d8+32,207cca+51,7db9e8+100 */
    background: #1e5799; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #1e5799 0%,#2989d8 32%,#207cca 51%,#7db9e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
  }

  
#weather-tile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  /* padding: 1rem 1.5rem; */
}

#weather-top-div {
  display: flex;
  height: 70%;
  width: 100%;
  flex-flow: row wrap;
  /* padding: 1rem 1rem 0rem; */
}

#weather-icon-div {
  flex: 50% 1;
  /* text-align: center; */
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

#weather-icon-img {
  height: 70%;
  -webkit-filter: invert(99%) sepia(98%) saturate(353%) hue-rotate(310deg) brightness(104%) contrast(80%);
          filter: invert(99%) sepia(98%) saturate(353%) hue-rotate(310deg) brightness(104%) contrast(80%);
}

#weather-desc {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
}

#weather-temps-div {
  flex: 50% 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
}

#weather-temp {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#weather-f {
  font-size: 5rem;
  font-weight: 600;
}

#weather-feels {
  font-size: 1.2rem;
}

#weather-location {
  font-size: 2rem;
  font-weight: 600;
  font-style: italic;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

#weather-bottom-div {
  /* font-size: 100%; */
  height: 30%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  /* padding: 0rem 1rem 1rem; */
}

#weather-bottom-div h4 {
  font-weight: 400;
}

.weather-bottom-inner-div {
  width: 50%;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  grid-row-gap: 10%;
  row-gap: 10%;
}

.weather-expected-temps-div {
  font-weight: 500;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-around;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

#weather-arrow-icon {
  height: 1.5rem;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.weather-arrow-up {
  -webkit-filter: invert(22%) sepia(94%) saturate(6896%) hue-rotate(1deg) brightness(97%) contrast(122%);
          filter: invert(22%) sepia(94%) saturate(6896%) hue-rotate(1deg) brightness(97%) contrast(122%);
}

.weather-arrow-down {
  -webkit-filter: invert(13%) sepia(100%) saturate(5083%) hue-rotate(239deg) brightness(84%) contrast(138%);
          filter: invert(13%) sepia(100%) saturate(5083%) hue-rotate(239deg) brightness(84%) contrast(138%);
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1);
}

/*

Tasks.js

*/

#task-tile {
  height: 100%;
  width: 100%;
  /* padding: 1rem 1.5rem; */
}

#task-top-div {
  height: 20%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  /* align-items: center; */
  /* padding-top: 0rem; */
}

#task-header {
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

#tasks-div {
  height: 60%;
  width: 100%;
  border: solid rgb(230, 226, 208) 1px;
  overflow: auto;
}

#tasks-filter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.tasks-filter-button {
  color: rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  background-color: transparent;
  border: solid rgb(230, 226, 208) 1px;
  border-radius: 1rem;
  padding: .25rem .5rem;
  margin: .5rem 0;
}

.tasks-filter-active {
  color: rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  background-color: blue;
  border: solid rgb(230, 226, 208) 1px;
  border-radius: 1rem;
  padding: .25rem .5rem;
  margin: .5rem 0;
}

#tasks-div ul {
  padding-left: 1.5rem;
}

#task-bottom-div {
  height: 20%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/*

TaskForm.js

*/

.task-form-container {
  width: 90%;
}

.task-form-inputs-div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.task-form-inputs-div input[type=text] {
  width: 100%;
  font-size: 1rem;
  padding: .25rem;
  border: solid rgb(230, 226, 208) 1px;
}

/* #task-submit-div { */
  /* flex: 10; */
  /* width: 20%; */
/* } */

.task-submit-button {
  /* width: auto;
  height: 1.75rem; */
  color:rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  padding: .25rem;
  background-color: transparent;
  border: solid rgb(230,
    226,
    208) 1px;
  border-radius: 1rem;
  margin: 0 .5rem;
}

.task-clear-button {
  width: auto;
  height: 1.75rem;
  color: rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  padding: .25rem;
  background-color: transparent;
  border: solid rgb(230, 226, 208) 1px;
  border-radius: 1rem;
}

/*

Task.js

*/

.task-div {
  height: auto;
  padding: .25rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
}

.done {
  text-decoration: line-through;
}

.task-buttons {
  width: 20%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.task-buttons p {
  font-size: .8rem;
  padding: .05rem;
  display: inline;
  cursor: pointer;
}
#posts-container {
  overflow: auto;
  /* padding: 1rem 3rem 1rem 2rem; */
  height: 100%;
}

#posts-container::-webkit-scrollbar {
  width: 0px;
}
#news-container {
  overflow: auto;
  /* padding: 1rem 3rem 1rem 2rem; */
  height: 100%;
}

#news-container p {
  padding-bottom: 2rem;
}

.news-article-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.news-article-header {
  /* padding: .4rem; */
  width: 100%;
}

.news-article-container h1 {
  font-size: 1.2rem;
}

.news-article-container-p {
  font-size: .8rem;
  width: 50%;
}

.news-article-container-p-missing-image {
  font-size: .8rem;
  width: 90%
}

.news-article-info {
  display: flex;
  justify-content: space-around;
}

/* #posts-container::-webkit-scrollbar {
  width: 0px;
} */
