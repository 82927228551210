.App {
  height: 80vh;
  width: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3f4c6b+0,3f4c6b+100;Blue+Grey+Flat */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#193759+0,1c3d66+100 */
  background: #193759; /* Old browsers */
  background: -moz-linear-gradient(top, #193759 0%, #1c3d66 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #193759 0%,#1c3d66 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #193759 0%,#1c3d66 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#193759', endColorstr='#1c3d66',GradientType=0 ); /* IE6-9 */
  /* padding: 1rem; */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

/* .App-header {
  margin: 0 auto;
  font-size: calc(10px + 2vmin);
} */