/*

Tasks.js

*/

#task-tile {
  height: 100%;
  width: 100%;
  /* padding: 1rem 1.5rem; */
}

#task-top-div {
  height: 20%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  /* align-items: center; */
  /* padding-top: 0rem; */
}

#task-header {
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

#tasks-div {
  height: 60%;
  width: 100%;
  border: solid rgb(230, 226, 208) 1px;
  overflow: auto;
}

#tasks-filter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.tasks-filter-button {
  color: rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  background-color: transparent;
  border: solid rgb(230, 226, 208) 1px;
  border-radius: 1rem;
  padding: .25rem .5rem;
  margin: .5rem 0;
}

.tasks-filter-active {
  color: rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  background-color: blue;
  border: solid rgb(230, 226, 208) 1px;
  border-radius: 1rem;
  padding: .25rem .5rem;
  margin: .5rem 0;
}

#tasks-div ul {
  padding-left: 1.5rem;
}

#task-bottom-div {
  height: 20%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/*

TaskForm.js

*/

.task-form-container {
  width: 90%;
}

.task-form-inputs-div {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.task-form-inputs-div input[type=text] {
  width: 100%;
  font-size: 1rem;
  padding: .25rem;
  border: solid rgb(230, 226, 208) 1px;
}

/* #task-submit-div { */
  /* flex: 10; */
  /* width: 20%; */
/* } */

.task-submit-button {
  /* width: auto;
  height: 1.75rem; */
  color:rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  padding: .25rem;
  background-color: transparent;
  border: solid rgb(230,
    226,
    208) 1px;
  border-radius: 1rem;
  margin: 0 .5rem;
}

.task-clear-button {
  width: auto;
  height: 1.75rem;
  color: rgb(230, 226, 208);
  font-size: .8rem;
  font-weight: 500;
  padding: .25rem;
  background-color: transparent;
  border: solid rgb(230, 226, 208) 1px;
  border-radius: 1rem;
}

/*

Task.js

*/

.task-div {
  height: auto;
  padding: .25rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
}

.done {
  text-decoration: line-through;
}

.task-buttons {
  width: 20%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.task-buttons p {
  font-size: .8rem;
  padding: .05rem;
  display: inline;
  cursor: pointer;
}